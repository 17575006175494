<template>
    <div id="demo-basic-card">
        <div class="vx-row">
            <!-- USER PROFILE CARD 2 - MINIMAL -->
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card class="p-2">
                    <router-link :to="{ name: 'professional-development-teacher-core-skill'}" class="flex align-items-center justify-content-center p-5" v-if="isTeacher">
                        <h2 class="mb-0">Core Skills</h2>
                    </router-link>
                    <router-link :to="{ name: 'professional-development-core-skill'}" class="flex align-items-center justify-content-center p-5"  v-else>
                        <h2 class="mb-0">Core Skills</h2>
                    </router-link>
                </vx-card>
            </div>
            <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
                <vx-card class="p-2">
                    <router-link :to="{ name: 'professional-development-teacher-fast-check'}" class="flex align-items-center justify-content-center p-5" v-if="isTeacher">
                        <h2 class="mb-0">Fast Checks</h2>
                    </router-link>
                    <router-link :to="{ name: 'professional-development-fast-check'}" class="flex align-items-center justify-content-center p-5" v-else>
                        <h2 class="mb-0">Fast Checks</h2>
                    </router-link>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return {
                isTeacher: false
            };
    },
    mounted() {
        this.isTeacher = this.$store.state.AppActiveUser.userType == 'teacher' ? true : false
        }
    }
</script>
